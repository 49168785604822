import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAn_dMIBfsXzJBUWCvQZxOKT33AcWxD0FQ",
    authDomain: "autoembed-cce71.firebaseapp.com",
    projectId: "autoembed-cce71",
    storageBucket: "autoembed-cce71.appspot.com",
    messagingSenderId: "504589826271",
    appId: "1:504589826271:web:1b6390234adf3fe31b516a",
    measurementId: "G-WS7P1C09HT"
};

// Initialize Firebase app
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firestore and Auth services
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export { db, auth, firebaseConfig };
export default firebaseApp;
